import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";


export class Date extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: [],
            time: [],
            activeDateTime: [],
            activeTime: '',
            activeDate: '',
            currentDate: '',
            calendar: '',
            nameMonth: '',
            activeMonth: '',
            isLoadTime: false,
            isLoadCalendar: false,
            error: false,
            errorText: '',
            isLoad: false,

        };


    }

    getTimeSlot(date) {
        let urlAPI2 = GlobalVariable.host() + '/open/mobile/booking/companies/available_time_slots?date=' + date;
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
        };

        this.setState(
            {

                isLoadTime: false,

            });

        axios({

            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                if (result.data.bad) {
                    this.setState(
                        {
                            time: [],
                            isLoadTime: true,
                            errorText: result.data.bad,
                            error: true,

                        });
                } else {

                    this.setState(
                        {
                            time: result.data.availableTimeSlots,
                            isLoadTime: true,
                            errorText: '',
                            error: false

                        });
                }


            }).catch(error => {
            this.setState({auth: false, isLoad: true, isLoadTime: false, error: true, time: []})
            // this.changeError(true)
        })

    }

    calendarChange(status) {
        let dateTime = sessionStorage.getItem('dateTime')
        if (dateTime) {
            if (JSON.parse(dateTime)[this.props.idPoint]) {
                this.setState({
                    activeDate: JSON.parse(dateTime)[this.props.idPoint].date,
                    activeTime: JSON.parse(dateTime)[this.props.idPoint].time,
                })
            }

        }
        // let urlAPI = GlobalVariable.host()+'/open/mobile/booking/companies/' + this.props.idPoint+ '/available_dates';
        let urlAPI1 = GlobalVariable.host() + '/open/mobile/booking/companies/calendar';
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
            status: status,
            currentDate: this.state.activeDate,
            activeMonth: this.state.activeMonth

        };


        axios({

            url: urlAPI1,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState(
                    {
                        calendar: result.data.calendar,
                        currentDate: result.data.currentDate,
                        activeDate: result.data.currentDate,
                        nameMonth: result.data.nameMonth,
                        activeMonth: result.data.activeMonth

                    });
                this.getTimeSlot(result.data.currentDate)

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })
    }


    componentDidMount() {
        let dateTime = sessionStorage.getItem('dateTime')
        if (dateTime) {
            if (JSON.parse(dateTime)[this.props.idPoint]) {
                // console.log('090')
                this.setState({
                    activeDate: JSON.parse(dateTime)[this.props.idPoint].date,
                    activeTime: JSON.parse(dateTime)[this.props.idPoint].time,
                })
            }


        }
        // let urlAPI = GlobalVariable.host()+'/open/mobile/booking/companies/' + this.props.idPoint+ '/available_dates';
        let urlAPI1 = GlobalVariable.host() + '/open/mobile/booking/companies/calendar';
        let service = sessionStorage.getItem('service')
        let staff = sessionStorage.getItem('staff')
        let activeService = {}
        let activeStaff = {}
        if (service) {
            activeService = JSON.parse(service)[this.props.idPoint]
        }
        if (staff) {
            activeStaff = JSON.parse(staff)[this.props.idPoint]

        }
        const data = {
            serviceIds: activeService,
            resourceId: activeStaff,
        };


        axios({

            url: urlAPI1,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                // console.log(result.data)
                this.setState(
                    {
                        isLoadCalendar: true,
                        calendar: result.data.calendar,
                        currentDate: result.data.currentDate,
                        activeDate: result.data.currentDate,
                        nameMonth: result.data.nameMonth,
                        activeMonth: result.data.activeMonth,
                        isLoad: true
                    });
                this.getTimeSlot(result.data.currentDate)

            }).catch(error => {
            this.setState({auth: false})
            // this.changeError(true)
        })

    }

    renderDate() {
        let days = ['ВC', 'ПН', 'ВТ', 'СР',
            'ЧТ', 'ПТ', 'СБ'];
        const {date, time, currentDate, activeDateTime} = this.state;
        if (date.length == 0) {
            return (
                <div>
                    Нет доступных дат
                </div>
            )
        }
        return (
            <div className={'row'}>


                <h4 style={{
                    textAlign: "center",
                    marginBottom: '10px'
                }}>{this.props.company.name}-{this.props.company.address} </h4>
                <h4>Дата</h4>
                {date.map(items => (
                    <div className={'col-2 col-md-1 date_block_item'}
                         style={{borderColor: activeDateTime != items.date.date ? 'black' : 'red'}} onClick={() => {
                        this.getTimeSlot(items.date.date)
                    }}>
                        {days[items.date.dayNumber]}<br/>
                        {items.date.day}

                    </div>


                ))}

            </div>
        )
    }

    renderTime() {
        const {isLoadTime, time, currentDate, activeTime, error, errorText} = this.state;

        if (errorText ) {
            return (
                <div>
                    {errorText}
                </div>
            )
        }
        if (!isLoadTime) {
            return (
                <div>
                    Загрузка...
                </div>
            )
        }

        if (time.length == 0) {
            return (
                <div>
                    Нет доступного времени
                </div>
            )
        }

        console.log(time)
        return (

            Object.entries(this.state.time).map(([k,v]) => (
                <div onClick={() => this.activeTime(k)}
                     className={activeTime == k ? 'col-2 col-md-1 date_block_item active' : 'col-2 col-md-1 date_block_item'}>
                    {k}
                </div>
            ))
        )
    }


    renderCalendar() {
        const {calendar, activeDate} = this.state;
        if (!this.state.isLoadCalendar) {
            return (<div style={{textAlign: 'center'}}> Загружаем данные! Ожидайте</div>
            )
        }
        if (calendar.length > 0) {
            return (
                <div className={'calendar'}>
                    <div style={{justifyContent: 'space-between'}} className={'d-flex'}>
                        <div><h5>{this.state.nameMonth}</h5></div>
                        <div></div>

                        <div className={'d-flex'}>
                            <div style={{cursor: 'pointer'}} onClick={() => this.calendarChange('-1')}><img
                                className="mr-3" src="/img/arrowLeft.png"/></div>
                            <div style={{cursor: 'pointer'}} onClick={() => this.calendarChange('+1')}><img
                                className="mr-3" src="/img/arrowRight.png"/></div>
                        </div>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'day'}>Пн</div>
                        <div className={'day'}>Вт</div>
                        <div className={'day'}>Ср</div>
                        <div className={'day'}>Чт</div>
                        <div className={'day'}>Пт</div>
                        <div className={'day'}>Сб</div>
                        <div className={'day'}>Вс</div>
                    </div>
                    <div style={{flexDirection: 'column'}} className={'d-flex'}>
                        {calendar.map((weeks) => (
                            <div className={'weeks'}>
                                {
                                    weeks.map((day) => (
                                        <div onClick={() => this.activeDate(day.date)} style={{cursor: "pointer"}}
                                             className={'day-num'}>
                                            {
                                                activeDate == day.date ?
                                                    <div className={'active'}>{day.day}</div> : <div
                                                        style={day.status ? {color: 'red'} : {color: 'black'}}> {day.day}</div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                    </div>

                </div>
            )
        }

    }

    activeDate(date) {
        if (date != null) {
            this.setState({activeDate: date});
            // this.setState({time: []});
            this.getTimeSlot(date)
        }


    }

    activeTime(time) {
        let urlAPI = GlobalVariable.host() + '/open/mobile/booking/companies/time_slots_change';

        const data = {
            date: this.state.activeDate,
            time: time,
        };


        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                // console.log(result.data)
                this.setState(
                    {});

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

        // console.log(time)
        this.setState({activeTime: time});
    }

    renderButton() {
        if (this.state.activeTime.length > 0 && this.state.activeDate.length > 0) {
            return (
                <Link style={{marginBottom: '40px'}}
                      to={"/" + this.props.mobileId + "/record/company-city/" + this.props.idCity + "/point/" + this.props.idPoint + ""}>
                    <div className={'button'}>
                        Выбрать время
                    </div>
                </Link>

            )
        } else {
            return (
                <div style={{marginBottom: '40px'}} className={'button-false'}>
                    Выбрать время
                </div>
            )
        }
    }


    render() {

        let services = this.state.services;
        // console.log(services)
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {
            return (
                <div className={'global date'}>
                    <div className={'header '}>
                        <Link className={'back-step'} to={'/point'}> <img className="mr-3"
                                                                          src="/img/arrow-left.svg"/></Link>

                        <div className={'title'}>Выберите дату и время</div>

                    </div>
                    <div className={'list-date'}>
                        {this.renderCalendar()}
                        <div style={{paddingBottom: '100px'}} className={'body_block'}>
                            <div className={'row'}>

                                <div className={'pt-5 pb-3'}>
                                    <h5>Выберите время для записи:</h5>
                                    <div className={'row date_block'}>
                                        {this.renderTime()}


                                    </div>
                                </div>

                                {/*{this.renderButton()}*/}
                                {/*{this.renderTime()}*/}


                            </div>
                        </div>
                    </div>

                    <Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>
                        <div className={'btn-active mt-3 mb-3 '}>
                            Продолжить
                        </div>
                    </Link>


                    <div className={'h-90'}>

                    </div>
                    <Menu></Menu>
                </div>
            )
        }


    }
}

export default Date;