import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";


export class Notifications extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            chat: []

        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);


    }


    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }


    componentDidMount() {
        // const data = {
        //     code: '1090',
        //     phone: '79625887109'
        // };
        //
        //
        // const data2 = {};
        //
        // axios({
        //
        //     url: GlobalVariable.host() + '/widget/profile/my-record',
        //     headers: {'Content-Type': 'application/json'},
        //     withCredentials: true,
        //     method: "GET",
        //     data: data2,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             recordsPrev: result.data.prevRecord,
        //             recordsNext: result.data.nextRecord,
        //             isLoad: true,
        //             auth: true,
        //             info: 'Двойной Успех',
        //         })
        //     }).catch(error => {
        //     this.setState({auth: false, isLoad: true})
        //     // window.location.href = '/' + this.props.widgetId + '/profile';
        // })

    }


    render() {


        return (
            <div className={'global notifications'}>
                <div className={'header '}>
                    <Link className={'back-step'} to={'/home'}> <img className="mr-3"
                                                                     src="/img/arrow-left.svg"/></Link>

                    <div className={'title'}>Уведомления</div>

                </div>

                <div className={'notifications-body'}>
                    <div className={'title'}>Уведомлений пока не поступило.</div>

                </div>


                <div className={'h-60'}>

                </div>
                <Menu></Menu>
            </div>
        )


    }
}

export default Notifications;