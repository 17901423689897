import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";


export class Record extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            phone: '',
            code: '',
            step: 'code',
            btnPhone: false,
            btnCode: false,

        };
        this.changePhone = this.changePhone.bind(this);
        this.changeCode= this.changeCode.bind(this);

    }
    changePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');


        let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(6, val.length)}`;

        num = num.trim();

        if (num.length <= 12) {
            this.setState({phone: num});
        }
        if(num.length == 11){
            this.setState({btnPhone: true});
        }
        else {
            this.setState({btnPhone: false});
        }


    }
    changeCode(event) {
        let  code = event.target.value.replace(/[^0-9]/g,"")
        if(code.length <5){
            this.setState({code: code});
        }
        if(code.length == 4){
            this.setState({btnCode: true});
        }
        else {
            this.setState({btnCode: false});
        }


    }

    renderButtonPhone() {
        if (this.state.phone.length == 4) {
            return (

                <div className={'btn-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        } else {
            return (

                <div className={'btn-no-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        }
    }

    renderButtonCode() {
        if (this.state.code.length == 4) {
            return (
                <div className={'btn-active mt-3 mb-3'}>
                    Продолжить
                </div>
            )
        } else {
            return (
                <div className={'btn-no-active mt-3 mb-3'}>
                    Продолжить
                </div>
            )
        }
    }


    componentDidMount() {
        const data = {
            code: '1090',
            phone: '79625887109'
        };


        const data2 = {};

        axios({

            url: GlobalVariable.host() + '/widget/profile/my-record',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data2,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    recordsPrev: result.data.prevRecord,
                    recordsNext: result.data.nextRecord,
                    isLoad: true,
                    auth: true,
                    info: 'Двойной Успех',
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })

    }


    render() {

        if (this.state.step == 'phone') {
            return (
                <div className={'global login'}>
                    <div className={'d-flex mt-3 mb-3'}>
                        <div>
                            <img src={'/img/logo.png'}/>
                        </div>
                        <div className={'pl-3'}>
                            <div className={'title1'}>Введите номер телефона</div>
                            <div className={'title2'}> Чтобы войти или стать клиентом Чио Чио</div>
                        </div>
                    </div>
                    <div className={'form mt-3 mb-3'}>
                        <div className={'input d-flex'}>
                            {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                            {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                            <input onChange={this.changePhone} style={{paddingLeft: '73px'}} value={this.state.phone}
                                   placeholder={'(xxx) xxx-xx-xx'}
                                   className={'input-phone '}/>
                        </div>
                    </div>
                    <div className={'checkbox mt-3 mb-3'}>
                        <p>Нажимая «Продолжить» вы принимаете Пользовательское соглашение и Политику
                            конфиденциальности</p>
                    </div>
                    {this.renderButtonPhone()}

                </div>
            )
        } else {
            return (
                <div className={'global login'}>
                    <div className={'d-flex mt-3 mb-3'}>
                        <div>
                            <img src={'/img/logo.png'}/>
                        </div>
                        <div className={'pl-3'}>
                            <div className={'title1'}>Введите код</div>
                            <div className={'title2'}> Чтобы войти или стать клиентом Чио Чио</div>
                        </div>
                    </div>
                    <div className={'form mt-3 mb-3'}>
                        <div className={'input d-flex'}>
                            {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                            {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                            <input value={this.state.code} placeholder={'x x x x'} onChange={this.changeCode}
                                   className={'input-code '}/>
                        </div>
                    </div>
                    <div className={'info mt-3 mb-3'}>
                        <p>Введите код из входящего звонка</p>
                    </div>
                    {this.renderButtonCode()}
                    <div className={'info mt-3 mb-3'} style={{color: '#9E9E9E'}}>
                        <p>Отправить код ещё раз <br/>
                            через: 50 сек.</p>
                    </div>

                </div>
            )
        }


    }
}

export default Record;