import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";


export class Staff extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staffs: [],
            activeStaff: [],
            staffsisLoad: true,
        };


    }


    chooseStaff(Staff) {
        let id = 1;
        if (Staff.id == 0) {

            id = 1;
        } else if (Staff) {
            id = Staff.id;
        }
        let urlAPI = GlobalVariable.host() + '/open/mobile/booking/companies/staff_change';
        const data = {
            id: id

        };
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                window.location.href = "/point" ;

            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })


    }

    componentDidMount() {


        let urlAPI = GlobalVariable.host() + '/open/mobile/booking/companies/resources';


        const data = {};

        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    staffs: result.data.resources,
                    activeStaff: result.data.idStaff,
                    staffsisLoad: false
                })


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }


    render() {

        let services = this.state.services;
        // console.log(services)

        return (
            <div className={'global staff'}>
                <div className={'header '}>
                    <Link className={'back-step'} to={'/point'}> <img className="mr-3"
                                                                      src="/img/arrow-left.svg"/></Link>

                    <div className={'title'}>Выберите сотрудника</div>

                </div>
                <div className={'list-staff mt-3'}>
                    {this.state.staffs.map(items => (
                        <div>
                        <div onClick={() => this.chooseStaff(items)} className={'staff-item mt-3 mb-3'} style={{}}>

                            <div className={'logo-block'}>
                                <div className={' logo'} style={{}}>
                                    <div
                                        style={{
                                            color: 'white',
                                            fontSize: "40px",
                                            textAlign: 'center'
                                        }}
                                    >{items.title[0]}</div>
                                </div>
                            </div>
                            <div className={'staff-item-block'}>
                                <h5 className={'staff-name'}>{items.title}</h5>
                                {/*<div>Топ-мастер</div>*/}

                                <h6 className={'staff-info-item'}>{items.description}</h6>
                                <div style={{width: '80px', height: '20px'}} className={'stars'}><img
                                    style={{width: '80px'}} className="mr-3 stars-img" src="/img/stars-5.png"/></div>

                            </div>
                            <div className={''} style={{marginRight: '20px', margin: "auto"}}>
                                <img className=""
                                     src={this.state.activeStaff.id == items.id ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                            </div>


                            {/*<div style={{marginTop: '10px'}} className={''}>*/}


                            {/*    <div className={'row date_block'}>*/}


                            {/*    </div>*/}

                            {/*</div>*/}


                        </div>
                            <hr/>
                        </div>


                    ))}
                </div>

                <Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>
                    <div className={'btn-active mt-3 mb-3 '}>
                        Продолжить
                    </div>
                </Link>


                <div className={'h-150'}>

                </div>
                <Menu></Menu>
            </div>
        )


    }
}

export default Staff;