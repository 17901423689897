import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";


export class MyRecord extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],
            recordsNext: [],
            isLoad: false,
            auth: true,
            activeRecord: '',
            balls: 0,

        };


    }




    componentDidMount() {

        const data = {};

        axios({

            url: GlobalVariable.host() + '/mobile/profile/my-record',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    recordsPrev: result.data.prevRecord,
                    recordsNext: result.data.nextRecord,
                    balls: result.data.balls,
                    isLoad: true,
                    auth: true
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: false})
            window.location.href = '/login' ;

        })
    }
    renderNextRecord(item) {
        if (this.state.activeRecord == item.id) {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id==this.state.activeRecord?'':item.id})} style={{cursor: 'pointer', height: '100px'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td className={'d-flex'}>{item.paid}&nbsp;₽
                        <div onClick={()=>this.deleteRecord(item.id)} style={{backgroundColor: 'red', height: '70px', width: '100px', margin:'5px', borderRadius:'15px', marginLeft:'15px'}}>

                            <div style={{paddingTop:'10px'}} >
                                <div style={{margin:'0 auto', width:'fit-content'}}><img src={'/img/Trush_Icon_UIA.png'}/></div>
                                <div style={{margin:'0 auto',color:'white', width:'fit-content'}}>Отменить</div>
                            </div>

                        </div></td>

                </tr>

            )
        } else {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id})} style={{cursor: 'pointer'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td>{item.paid} ₽</td>

                </tr>
            )
        }
    }
    deleteRecord(idRecord){
        // console.log(idRecord)
        const data = {
            idRecord:idRecord
        };

        axios({

            url: GlobalVariable.host() + '/widget/profile/record/delete',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({

                })
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })
    }
    render() {

        let services = this.state.services;
        // console.log(services)
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        }
        else {


        return (
            <div className={'global my-record'}>
                <div className={'header '}>
                    <Link className={'back-step'} to={'/home'}> <img className="mr-3"
                                                                      src="/img/arrow-left.svg"/></Link>

                    <div className={'title'}>Мои записи</div>

                </div>
                <div style={{backgroundColor:'rgba(228, 1, 54, 1', height:'59px', width:'95%'}}  className={'d-flex mt-3 info-balls'}>
                    <div>Накопленно</div>
                    <div>{this.state.balls} баллов</div>
                </div>
                <div className={'list-my-record'}>
                    <div className={'d-flex mt-3'}>
                        <div><img style={{marginBottom: '3px'}} className="mr-3"
                                  src={"/img/Ellipse1.png"}/></div>
                        <div style={{color: '#0D263F', marginLeft: '10px'}}>Будущая запись</div>
                    </div>
                    <div className={'d-flex mt-3 mb-3'}>
                        <div><img style={{marginBottom: '3px'}} className="mr-3"
                                  src={"/img/Ellipse2.png"}/></div>
                        <div style={{color: '#939393', marginLeft: '10px'}}>Прошлая запись</div>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Дата</th>
                            <th scope="col">Услуги</th>
                            <th scope="col">Стоимость</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.recordsNext.map((item) => (

                                this.renderNextRecord(item)
                            ))
                        }
                        {
                            this.state.recordsPrev.map((item) => (
                                <tr
                                    className={'prevRecord'}>
                                    <th>{item.date} {item.time}</th>
                                    <td>{item['service'].map((itemService) => (
                                        <div>{itemService.serviceName}</div>
                                    ))}</td>
                                    <td>{item.paid} ₽</td>

                                </tr>
                            ))
                        }

                        </tbody>
                    </table>

                </div>

                {/*<Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>*/}
                {/*    <div className={'btn-active mt-3 mb-3 '}>*/}
                {/*        Продолжить*/}
                {/*    </div>*/}
                {/*</Link>*/}


                <div className={'h-150'}>

                </div>
                <Menu></Menu>
            </div>
        )
        }

    }
}

export default MyRecord;