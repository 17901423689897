import React, {Component} from 'react';
import {Link} from "react-router-dom";


export class Menu extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};


    }


    render() {


        // console.log(window.location.search)
        return (<div className={'menu'}>
                <Link to={'/home'}>
                    <div className={'item'}>
                        <div>  <img src={'img/home-icon.png'}/></div>
                        Домой
                    </div>
                </Link>
                <Link to={'/my-record'}>
                    <div className={'item'}>
                        <div>  <img src={'img/calendar2-off.png'}/></div>
                        Мои записи
                    </div>
                </Link>
                <Link to={'/chat'}>
                    <div className={'item'}>
                        <div> <img src={'img/chat.png'}/></div>
                        Чат
                    </div>
                </Link>
                <Link to={'/profile'}>
                    <div className={'item'}>
                        <div><img src={'img/profile-circle-off.png'}/></div>

                        Профиль
                    </div>
                </Link>
            </div>
        )


    }
}

export default Menu;