import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import ReactDOM from 'react-dom';
import Menu from "../Menu";
import Preloader from "../Preloader";


export class Home extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: '',
            service: [],
            dateTime: '',
            activeBlock: '',
            openChangePoint: false,
            isLoad: false,
            auth: false,
            countNextRecord: 0,
            activeCompany: [],

        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.changeStatus = this.changeStatus.bind(this);

    }


    updateData = (value) => {

        this.setState({activeBlock: value})
    }
    updateService = (value) => {
        sessionStorage.setItem("serviceActive", JSON.stringify(value));
        this.setState({service: value, activeBlock: 'menu'})
    }
    updateStaff = (value) => {
        sessionStorage.setItem("staffActive", JSON.stringify(value));
        this.setState({staff: value, activeBlock: 'menu'})
    }
    updateDateTime = (value) => {
        sessionStorage.setItem("dateTime", JSON.stringify(value));
        this.setState({dateTime: value, activeBlock: 'menu'})
    }
    updateCompany = (value) => {
        sessionStorage.setItem("companyActive", JSON.stringify(value));
        this.setState({activeCompany: value, activeBlock: 'menu'})
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        let urlAPI = GlobalVariable.host() + '/mobile/client/home';


        const data = {};

        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    activeCompany: result.data,
                    datework: result.data.days,
                    countNextRecord: result.data.countNextRecord,
                    isLoad: true,
                    auth: true

                })


            }).catch(error => {
            this.clientInfoNoAuth()
            this.setState({
                auth: false,
                isLoad: true,

            })

            // window.location.href = '/' ;

        })
    }

    clientInfoNoAuth() {
        document.addEventListener('click', this.handleClickOutside, true);
        let urlAPI = GlobalVariable.host() + '/open/client/home';


        const data = {};

        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    activeCompany: result.data,
                    datework: result.data.days,
                    isLoad: true,
                    auth: false

                })


            }).catch(error => {
            this.setState({
                auth: false,
                isLoad: true,

            })
            // window.location.href = '/' ;

        })
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    changeStatus() {
        this.setState({openChangePoint: true})


    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        // console.log(this.state.openChangePoint)
        // console.log(event.target.className)
        if (this.state.openChangePoint == true) {
            if (event.target.className === "changePointBlock") {

                this.setState({openChangePoint: false})
            }
        }


    }

    renderChangePoint() {
        if (this.state.openChangePoint == true) {
            return (
                <div className={'changePointBlock'}>
                    <div className={'changePoint'}>
                        <div className={'block-address mt-3 mb-3'}>

                            {this.state.activeCompany.city}, {this.state.activeCompany.address}
                        </div>
                        <div className={'block-work mb-3'}>
                            <div>Режим работы:</div>
                            {this.state.datework}

                        </div>
                        <div className={'block-phone mb-3'}>
                            <div>Телефон:</div>
                            <div>8 800 600-65-16</div>
                        </div>
                        <Link to={'/country'}>
                            <div className={'btn-active mt-3 mb-3'}>
                                Сменить филиал
                            </div>
                        </Link>
                    </div>
                </div>
            )
        }

    }

    renderBlockBalls() {
        console.log(this.state.auth)
        if (this.state.auth == true) {
            return (
                <div className={'title'}>{this.state.activeCompany.ball} баллов</div>
            )
        } else {
            return (
                <div className={'title'}>Мои баллы</div>
            )
        }
    }

    renderBlockName() {

        if (this.state.auth == true) {
            return (
                <div className={'title'}>Приветствуем в Чио Чио,
                    <br/>
                    <span>{this.state.activeCompany.nameClient}</span>
                </div>
            )
        } else {
            return (
                <div className={'title'}>Приветствуем Вас в Чио Чио

                </div>
            )
        }
    }

    renderCountRecord() {
        if(this.state.countNextRecord > 0){
            return(<div className={'title'}>Записи ({this.state.countNextRecord})</div>)

        }
        else {
            return(<div className={'title'}>Записи</div>)
        }
    }

    renderRecord() {
        if (!this.state.activeCompany.recordOnline) {
            return (
                <div className={'body_block_record_and_history'}>
                    <h2 className={'title'}>Записаться</h2>
                    <div className={'block_record_and_history d-flex'}>
                        <div className={'onlineRecord'}>
                            <Link to={'/point'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon-staff.png'}/>
                                    </div>
                                    <div className={'title'}>К мастеру</div>
                                </div>
                            </Link>
                            <Link to={'/point'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon-service.png'}/>
                                    </div>
                                    <div className={'title'}>На услугу</div>
                                </div>
                            </Link>
                            <Link to={'/point'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon-time.png'}/>
                                    </div>
                                    <div className={'title'}>По времени</div>
                                </div>
                            </Link>
                        </div>
                        <div className={'history'}>
                            <Link to={'/my-record'}>
                                <div className={'btn_block'}>
                                    <div className={'image'}>
                                        <img src={'/img/icon4.png'}/>
                                    </div>

                                    {this.renderCountRecord()}

                                </div>
                            </Link>
                            <div className={'btn_block'}>
                                <div className={'image'}><img src={'/img/ball-icon.png'}/></div>
                                {this.renderBlockBalls()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'body_block_record_and_history'}>
                    <h2 className={'title'}>Записаться</h2>
                    <div className={'block_record_and_history d-flex'}>
                        <div className={'onlineRecord'}>
                            <div className={'no-record'}>
                                <div className={'image'}><img src={'/img/no-record.png'}/></div>
                                <div className={'text'}>Онлайн запись не доступна</div>
                            </div>

                        </div>
                        <div className={'history'}>
                            <div className={'btn_block'}>
                                <div className={'image'}>
                                    <img src={'/img/icon4.png'}/>
                                </div>
                                {this.renderCountRecord()}
                            </div>
                            <div className={'btn_block'}>
                                <div className={'image'}><img src={'/img/ball-icon.png'}/></div>
                                {this.renderBlockBalls()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderBlockActivePoint() {
        if (this.state.auth == true) {
            return (
                <div onClick={() => this.changeStatus()} className={'change-city d-flex'}>
                    <div className={'icon-left'}><img src={'/img/point.png'}/></div>
                    <div
                        className={'title-point'}>{this.state.activeCompany.city}, {this.state.activeCompany.address}</div>
                    <div className={'icon-right'}><img src={'/img/arrowRight2.png'}/></div>

                </div>
            )
        } else {
            return (
                <div onClick={() => this.changeStatus()} className={'change-city d-flex'}>
                    <div className={'icon-left'}><img src={'/img/point.png'}/></div>
                    <div
                        className={'title-point'}>{this.state.activeCompany.city}, {this.state.activeCompany.address}</div>
                    <div className={'icon-right'}><img src={'/img/arrowRight2.png'}/></div>

                </div>
            )
        }
    }

    render() {
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {
            return (
                <div className={'global home'}>

                    <div className={'hello '}>
                        <div className={'d-flex hello-block'}>
                            {this.renderBlockName()}
                            <Link to={'/notifications'}>
                                <div className={'mess'}>
                                    <img src={'/img/kolokol.png'}/>
                                </div>
                            </Link>
                        </div>
                        {this.renderBlockActivePoint()}

                    </div>
                    <div className={'adv d-flex'}>
                        <div className={'rectangle'}>
                            <div className={'image'} style={{backgroundImage: 'url("/img/banner1.png")'}}>

                            </div>
                            <div className={'title'}>Комбо</div>
                        </div>
                        <div className={'rectangle'}>
                            <div className={'image'} style={{backgroundImage: 'url("/img/banner2.png")'}}>

                            </div>
                            <div className={'title'}>Пенсионерам</div>
                        </div>
                        <div className={'rectangle'}>
                            <div className={'image'} style={{backgroundImage: 'url("/img/banner3.png")'}}>

                            </div>
                            <div className={'title'}>Студентам</div>
                        </div>
                        <div className={'rectangle'}>
                            <div className={'image'} style={{backgroundImage: 'url("/img/banner4.png")'}}>

                            </div>
                            <div className={'title'}>Окрашивание</div>
                        </div>

                    </div>
                    {this.renderRecord()}
                    {/*<div className={'franshiza'}>*/}
                    {/*    <img src={'/img/franchiza.png'}/>*/}
                    {/*</div>*/}
                    {/*<div className={'stock '}>*/}
                    {/*    <img src={'/img/stock.png'}/>*/}
                    {/*</div>*/}
                    <div className={'h-60'}>

                    </div>

                    {this.renderChangePoint()}
                    <Menu></Menu>
                </div>
            )
        }


    }
}

export default Home;