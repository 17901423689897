import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";


export class ProfileChange extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            date: '',
            email: '',
            btnPhone: false,
            btnCode: false,
            genderM: false,
            genderG: false,

        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeGenderM = this.changeGenderM.bind(this);
        this.changeGenderG = this.changeGenderG.bind(this);
        this.openDelBlock = this.openDelBlock.bind(this);

    }

    changeGenderG(event) {
        const target = event.target;
        ;

        if (target.checked == true) {
            this.setState({
                genderM: false,
                genderG: true
            });
        } else {
            this.setState({
                genderM: false,
                genderG: false
            });
        }

    }

    changeGenderM(event) {
        const target = event.target;
        ;


        if (target.checked == true) {
            this.setState({
                genderM: true,
                genderG: false
            });
        } else {
            this.setState({
                genderM: false,
                genderG: true
            });
        }
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }

    renderButton() {
        let gender = false;
        if (this.state.genderM || this.state.genderG) {
            gender = true;
        }
        if (this.state.name && this.state.date && gender) {
            return (

                <div onClick={() => this.sender()} className={'btn-active mt-3 mb-3'}>
                    Сохранить
                </div>

            )
        } else {
            return (

                <div className={'btn-no-active mt-3 mb-3'}>
                    Сохранить
                </div>

            )
        }
    }

    sender() {

        let data = {
            name: this.state.name,
            date: this.state.date,
            email: this.state.email,
            genderM: this.state.genderM,
            genderG: this.state.genderG,
        };
        axios({

            url: GlobalVariable.host() + '/mobile/profile/save',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }

    exit() {

        let data = {};
        axios({

            url: GlobalVariable.host() + '/open/widget/v2/security/exit',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }
    postDelProfile() {

        let data = {};
        axios({

            url: GlobalVariable.host() + '/mobile/profile/delete',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }

    openDelBlock() {
        this.setState({openChangePoint: true})
    }

    renderChooseDelProfile() {
        if (this.state.openChangePoint == true) {
            return (
                <div className={'changePointBlock'}>
                    <div className={'changePoint'}>
                        <div>Вы действительно хотите удалить свой профиль? (Все ваши баллы удалятся)</div>

                        <div onClick={()=>this.postDelProfile()} className={'btn-active mt-3 mb-3'}>
                            ДА
                        </div>
                        <div className={'btn-active mt-3 mb-3'}>
                            НЕТ
                        </div>

                    </div>
                </div>
            )
        }

    }

    componentDidMount() {


        axios({

            url: GlobalVariable.host() + '/mobile/profile/show',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    name: result.data.name,
                    date: result.data.date,
                    email: result.data.email,
                    isLoad: true,
                    auth: true,
                    info: result.data,
                    genderM: result.data.sex == 1 ? true : false,
                    genderG: result.data.sex == 2 ? true : false,
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: false})
            window.location.href = '/login';
        })

    }


    render() {
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {

            return (
                <div className={'global profile-change'}>
                    <div className={'header '}>
                        <Link className={'back-step'} to={'/home'}> <img className="mr-3"
                                                                         src="/img/arrow-left.svg"/></Link>

                        <div className={'title'}>Редактировать профиль</div>

                    </div>
                    <div className={'form-profile'}>
                        <div className={'form mt-3 mb-3'}>
                            <div className={'input-block d-flex'}>
                                {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                <input value={this.state.name} placeholder={'Введите Ваше имя'}
                                       onChange={this.changeName}
                                       className={'input '}/>
                            </div>
                        </div>
                        <div className={'d-flex gender'}>
                            <div><input
                                checked={this.state.genderM}
                                onChange={this.changeGenderM}
                                name="m"
                                type="checkbox"
                                className="form-check-input"
                                value=""/>Вы мужчина
                            </div>
                            <div><input
                                checked={this.state.genderG}
                                onChange={this.changeGenderG}
                                name="g"
                                type="checkbox"
                                className="form-check-input"
                                value=""/>Вы женщина
                            </div>
                        </div>
                        <div className={'form mt-3 mb-3'}>
                            <div className={'input-block d-flex'}>
                                {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                {/*<input type="date" id="start" name="trip-start" value={this.state.date} min="1945-01-01" max="2030-12-31" />*/}
                                <input type="date" value={this.state.date}
                                       onChange={this.changeDate}
                                       className={'input'}/>
                            </div>
                        </div>
                        <div className={'form mt-3 mb-3'}>
                            <div className={'input-block d-flex'}>
                                {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                <input value={this.state.email} placeholder={'Укажите ваш email'}
                                       onChange={this.changeEmail}
                                       className={'input'}/>
                            </div>
                        </div>
                    </div>
                    {this.renderButton()}
                    <div onClick={() => this.exit()} className={'btn-active mt-3 mb-3'}>
                        Выход
                    </div>
                    <div onClick={() => this.openDelBlock()} style={{textAlign: 'center'}} className={' mt-3 mb-3'}>
                        Удалить мою учетную запись
                    </div>

                    <div className={'h-60'}>

                    </div>
                    <div  style={{textAlign: 'center'}} className={' mt-3 mb-3'}>
                        Версия: 1.1.2
                    </div>
                    {this.renderChooseDelProfile()}
                    <Menu></Menu>
                </div>
            )
        }


    }
}

export default ProfileChange;