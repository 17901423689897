import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";


export class Start extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: '+++++++++++',
            service: [],
            dateTime: '',
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],

        };


    }


    componentDidMount() {


    }


    render() {
        return (
            <div className={'global start'}>
                <div className={'top-block'}>
                    <div className={'rectangle'}>
                        <div>
                        <span>147</span> <br/> городов
                        </div>
                    </div>
                    <div className={'rectangle'}>
                        <div>
                        постригли
                        <span> 3,8 млн </span>
                        любимых
                        клиентов
                        </div>
                    </div>
                    <div className={'rectangle'}>
                        Россия
                        Беларуссия
                        Казахстан
                        Грузия
                    </div>
                </div>
                <div className={'title'}>
                    Заходите, рады вас видеть!

                </div>
                <Link to={'/login'}>
                <div className={'btn-active'}>

                    Войти
                </div>
                </Link>
            </div>
        )


    }
}

export default Start;