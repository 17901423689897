import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";


export class ProfileChange extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            chat: []

        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);


    }


    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }


    componentDidMount() {
        // const data = {
        //     code: '1090',
        //     phone: '79625887109'
        // };
        //
        //
        // const data2 = {};
        //
        // axios({
        //
        //     url: GlobalVariable.host() + '/widget/profile/my-record',
        //     headers: {'Content-Type': 'application/json'},
        //     withCredentials: true,
        //     method: "GET",
        //     data: data2,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             recordsPrev: result.data.prevRecord,
        //             recordsNext: result.data.nextRecord,
        //             isLoad: true,
        //             auth: true,
        //             info: 'Двойной Успех',
        //         })
        //     }).catch(error => {
        //     this.setState({auth: false, isLoad: true})
        //     // window.location.href = '/' + this.props.widgetId + '/profile';
        // })

    }


    render() {


        return (
            <div className={'global chat'}>
                <div style={{padding: '15px'}}>
                <div className={'title mb-3'}>
                    Чат
                </div>
                <div className={'chat-body'}>
                    <img  src={'/img/dev.png'}/>
                    <div className={'title'}>Данная страница в разработке, скоро она заработает</div>
                </div>

                {/*<div className={'small-title'}>Диалог с call - центром Чио Чио*/}
                {/*</div>*/}
                {/*<div className={'chat-body'}>*/}
                {/*    <div className={'user'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*    <div className={'call-center'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*    <div className={'user'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*    <div className={'call-center'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*    <div className={'user'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*    <div className={'call-center'}>*/}
                {/*        Привет!*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={'keyboard'}>*/}
                {/*    <div className={'from'}>*/}
                {/*        <textarea value={this.state.name} onChange={this.changeName}*/}
                {/*                  className={'input '}/>*/}
                {/*    </div>*/}
                {/*    <div className={'btn-send'}>*/}
                {/*        <img src={'/img/send-mess-icon.png'}/>*/}
                {/*    </div>*/}

                {/*</div>*/}


                <div className={'h-60'}>

                </div>
                </div>
                <Menu></Menu>
            </div>
        )


    }
}

export default ProfileChange;