import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import {Redirect} from "react-router-dom";
import Preloader from "../Preloader";

export class Point extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: false,
            onlineRecord: null,
            service: false,
            dateTime: false,
            activeBlock: '',
            activeCompany: [],
            companyInfo: [],
            auth: false,
            name: '',
            phone: '',
            record: [],
            star: [],
            isLoad: false

        };


    }


    componentDidMount() {

        console.log(this.props)
        const data = {
            'activeCompanyCity': this.props.idCity,
            'activePoint': this.props.idPoint,
            'link': 'changeCompany'
        };

        let staff = '';
        let dateTime = '';
        let service = '';
        axios({

            url: GlobalVariable.host() + '/open/mobile/booking/companies/city/info-point/',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(this.state)
                this.setState({
                    companyInfo: result.data.points,
                    staff: result.data.status.staff,
                    service: result.data.status.service,
                    dateTime: result.data.status.dateTime,
                    onlineRecord: result.data.onlineRecord,
                    isLoad: true,

                })

                staff = result.data.status.staff;
                service = result.data.status.service;
                dateTime = result.data.status.dateTime;

            }).catch(error => {
            this.setState({auth: false, isLoad: false})
            // this.changeError(true)
        })

        console.log(this.state)
    }


    renderButton() {

            if (this.state.staff && this.state.service && this.state.dateTime) {
                return (<Link to={'/final-record' }>

                    <div className={'btn-active mt-3 mb-3'}>
                        Оформить визит
                    </div>
                </Link>)
            } else {
                return (
                    <div className={'btn-no-active mt-3 mb-3'}>
                        Оформить визит

                    </div>


                )
            }


    }

    renderBody() {
        if (!this.state.onlineRecord) {
            return (
                <div className={'point-block'}>

                    <Link
                        to={"/service"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/Filter_Icon_UIA.png"/>
                            <div style={{marginLeft: '10px'}}>Услуга</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.service ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>
                        </div>

                    </Link>
                    <hr/>
                    <Link
                        to={"/date"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/calendar.png"/>
                            <div style={{marginLeft: '10px'}}>Дата&nbsp;и&nbsp;время</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.dateTime ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>
                        </div>

                    </Link>
                    <hr/>
                    <Link
                        to={"/staff"}>
                        <div className={'d-flex'}>
                            <img style={{width: '25px'}} className="mr-3" src="/img/user-square.png"/>
                            <div style={{marginLeft: '10px'}}>Мастер</div>
                            <div style={{width: '100%', justifyContent: 'flex-end'}} className={'d-flex'}>
                                <div style={{marginRight: '20px'}}><img className="mr-3"
                                                                        src={this.state.staff ? "/img/Ellipse-true.png" : "/img/EllipseFalse.png"}/>
                                </div>
                            </div>

                        </div>

                    </Link>

                </div>
            )
        } else {
            return (
                <div className={'point-block no-record'}>

                    <div className={'image'}><img src={'/img/no-record2.png'}/></div>
                    <div className={'text'}>Онлайн запись не доступна</div>

                </div>
            )
        }
    }

    render() {
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {
            return (

                <div className={''} id={'point-item'}>

                    <div className={'top-block'} style={{}}>
                        <img className={'logoPoint'} style={{width: '100%', height: '100%', objectFit: "cover"}}
                             src={'/img/logo_point.png'}
                            // src={this.state.companyInfo.logo}
                             alt=""/>

                        <Link to={"/country"}>
                            <div style={{cursor: "pointer"}} className={'backStep d-flex'}>
                                <img style={{height: 'fit-content', margin: 'auto 0'}} className="mr-3"
                                     src="/img/arrow-left.png"/>
                                <div style={{marginLeft: '10px', color: 'white'}}>Записаться</div>
                            </div>
                        </Link>
                        <div className={'header-block'}>
                            <div>
                                <div style={{justifyContent: 'space-between'}} className={'d-flex mb-2 '}>

                                    <div className={'title'}>Чио Чио {this.state.companyInfo.city}</div>
                                    <div className={'stars'}><img style={{width: '20px'}} className="mr-3 stars-img"
                                                                  src="/img/stars-5.png"/></div>


                                </div>
                                <div style={{cursor: "pointer", marginBottom: '10px'}} className={'city-point'}>
                                    <img className="mr-3" src="/img/pointMap.png"/>
                                    <div className={'address'}
                                         style={{fontSize: '15px'}}>{this.state.companyInfo.address}</div>
                                </div>
                            </div>


                        </div>
                    </div>

                    {this.renderBody()}
                    {this.renderButton()}
                    <div className={'h-60'}>

                    </div>
                    <Menu></Menu>
                </div>

            )


        }
    }
}

export default Point;