import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";
import * as serviceWorker from './serviceWorker';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.css';
import Global from "./components/Global";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Start from "./components/Start/Start";
import ProfileChange from "./components/Profile/ProfileChange";
import Record from "./components/Record/Record";
import Index from "./components/Index";
import Country from "./components/ListCompany/Country";
import City from "./components/ListCompany/City";
import Point from "./components/ListCompany/Point";
import Date from "./components/Record/Date";
import Service from "./components/Record/Service";
import Staff from "./components/Record/Staff";
import MyRecord from "./components/Record/MyRecord";
import ProfileCreate from "./components/Profile/ProfileCreate";
import Chat from "./components/Chat/Chat";
import Notifications from "./components/Notifications/Notifications";
import RecordFinal from "./components/Record/RecordFinal";
import CityPoint from "./components/ListCompany/CityPoint";


ReactDOM.render(
    <Router>

        <Switch>


            <Route path="/record" children={<Record/>}/>
            <Route path="/my-record" children={<MyRecord/>}/>
            <Route path="/country" children={<Country/>}/>
            <Route path="/city/:idCity" children={<CityPointFunc/>}/>
            <Route path="/point/:idPoint" children={<PointFunc/>}/>
            <Route path="/point" children={<Point/>}/>



            <Route path="/date" children={<Date/>}/>
            <Route path="/service" children={<Service/>}/>
            <Route path="/staff" children={<Staff/>}/>
            <Route path="/final-record" children={<RecordFinal/>}/>
            <Route path="/home" children={<Home/>}/>
            <Route path="/login" children={<LoginFunc/>}/>
            <Route path="/start" children={<Start/>}/>
            <Route path="/chat" children={<Chat/>}/>
            <Route path="/notifications" children={<Notifications/>}/>
            <Route path="/profile-create" children={<ProfileCreate/>}/>
            <Route path="/profile" children={<ProfileChange/>}/>
            <Route path="/" children={<Home/>}/>
        </Switch>
    </Router>,
    document.getElementById('root')
);

function HomeFunc() {

        return <Home />;
}
function LoginFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let {widgetId, idCity} = useParams();


    return <Login />;
}
function PointFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let { idPoint} = useParams();


    return <Point idPoint={idPoint} />;
}
function CityPointFunc() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.

    let { idCity} = useParams();


    return <CityPoint idCity={idCity} />;
}




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
