import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";


export class Service extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            services: [],
            serviceIsLoad: true,
            error: false,
            activeService: [],
            activeFullService: null,
            activeServicePrice: 0,
            isLoad: false,
            isLoadPrice: false,
        };


    }


    componentDidMount() {
        let urlAPI = GlobalVariable.host() + '/open/mobile/booking/companies/services/';


        const data = {}
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState(
                    {
                        services: result.data.services,
                        activeService: result.data.activeService,
                        activeServicePrice: result.data.activeServicePrice,
                        isLoad: true,

                    });


            }).catch(error => {
            this.setState({auth: false, error: true})
            // this.changeError(true)
        })


    }

    changeActiveFullService(service) {
        let activeFullService = this.state.activeFullService
        if (activeFullService == service.id) {
            this.setState({
                activeFullService: null
            });
        } else {
            this.setState({
                activeFullService: service.id
            });
        }


    }

    changeActiveService(service, type) {
        let activeService = this.state.activeService


        let urlAPI = GlobalVariable.host() + '/open/mobile/booking/companies/services/change';


        const data = {
            idService: service.id

        }
        axios({

            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState(
                    {
                        activeService: result.data.activeService,
                        activeServicePrice: result.data.activeServicePrice,
                        isLoadPrice: true,

                    });


            }).catch(error => {
            this.setState({auth: false, isLoadPrice: false, error: true})
            // this.changeError(true)
        })
        // this.setState({
        //     activeService: [service]
        // });
        if (activeService) {
            let indexService: number[] = [];
            for (let i = 0; i < activeService.length; i++) {
                if (activeService[i].id == service.id) {
                    indexService.push(i)
                }

            }


            if (indexService.length == 0) {

                this.setState({
                    activeService: activeService.concat(service)
                });

            }


            if (indexService.length > 0) {
                // console.log(indexService)
                let result = activeService.splice(indexService[0], 1)
                //  console.log(result )
                this.setState({
                    activeService: activeService
                });
            }
        }


    }

    renderService(itemService) {
        let activeService = this.state.activeService;
        if (!activeService) {
            activeService = [];
        }
        sessionStorage.setItem('service', JSON.stringify({[this.props.idPoint]: activeService}));
        // console.log(this.state.serviceIsLoad)
        if (this.state.serviceIsLoad) {
            return (
                <div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '70px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                        <div className={'mt-2 mb-2 preloader-gradient'} style={{width: '250px', height: '20px'}}></div>
                    </div>


                </div>
            )

        }
        let indexService: number[] = [];
        // console.log(activeService)
        for (let i = 0; i < activeService.length; i++) {
            if (activeService[i].id == itemService.id) {
                indexService.push(i)
            }

        }


    }

    renderButtonService(itemService) {
        let activeService = this.state.activeService;
        if (!activeService) {
            activeService = [];
        }
        sessionStorage.setItem('service', JSON.stringify({[this.props.idPoint]: activeService}));
        // console.log(this.state.serviceIsLoad)

        let indexService: number[] = [];
        // console.log(activeService)
        for (let i = 0; i < activeService.length; i++) {
            if (activeService[i].id == itemService.id) {
                indexService.push(i)
            }

        }

        if (indexService.length == 0) {
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="23" height="23" rx="4.5" stroke="#0D263F"/>
                </svg>


            )
        } else {

            return (
                <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="0.5" width="24" height="24" rx="4.5" fill="#0D263F" stroke="#0D263F"/>
                    <path d="M8.75 12.5L11.58 15.33L17.25 9.67004" stroke="white" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            )
        }

    }

    onMapClick(e) {
        window.location.href = "/point"
    }

    renderFullPriceAndService() {

        return (
            <div className={'service-info'}>
                <div className={'all-service'}>{this.state.activeService.map(item => (
                    item.title + ' '

                ))}</div>
                <div className={'price'}>Итог: {this.state.activeServicePrice}₽</div>
                <div className={'info'}>*Зависит от длины волос</div>
            </div>
        )
    }

    render() {

        let services = this.state.services;
        // console.log(services)
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {
            return (
                <div className={'global service'}>
                    <div className={'header '}>

                        <div onClick={this.onMapClick} className={'back-step'}><img className="mr-3"
                                                                                    src="/img/arrow-left.svg"/></div>
                        <div className={'title'}>Выберите услуги</div>

                    </div>
                    {this.renderFullPriceAndService()}
                    <div className={'list-service'}>
                        {
                            services.map(itemGroup => (
                                <div className={''}>

                                    <div onClick={() => {
                                        this.changeActiveService(itemGroup, 'add')
                                    }} style={{flexDirection: 'row', justifyContent: 'space-between'}}
                                         className={'  d-flex serviceTitle'}>
                                        <div className={''} style={{fontWeight: 700}}>{itemGroup.titleGroup}</div>

                                    </div>
                                    <div className={''}>
                                        <div>
                                            {itemGroup.servicesList.map((itemService) => (

                                                    <div key={itemService.id} className={'col-12 row service-item'}
                                                         onClick={() => this.changeActiveService(itemService, 'add')}
                                                         style={{cursor: "pointer"}}
                                                    >

                                                        <div
                                                            className={'col-12  d-flex serviceList'}>
                                                            <div className={'d-flex'} style={{
                                                                flexDirection: "row",
                                                                justifyContent: 'space-between',
                                                                width: '100%'
                                                            }}>
                                                                <div className={'btn_wrapper'}>
                                                                    <div>
                                                                        {this.renderButtonService(itemService)}


                                                                    </div>

                                                                </div>
                                                                <div style={{width:'100%', paddingLeft:'5px'}}>
                                                                    <div
                                                                        className={'service-title'}>{itemService.title}</div>


                                                                </div>
                                                                <div
                                                                    style={{
                                                                        fontWeight: 600,

                                                                    }}>{itemService.price}₽
                                                                </div>

                                                            </div>

                                                        </div>
<hr style={{marginTop:'18px'}}/>

                                                    </div>
                                                )
                                            )}


                                        </div>

                                        {/*<div onClick={() => this.changeActiveFullService(items)}*/}
                                        {/*     style={{cursor: 'pointer', textAlign:'center', textDecoration:'underline dotted',textUnderlineOffset: '4px'}} className={' '}>*/}
                                        {/*    {this.state.activeFullService==items.id?"Скрыть все услуги":"Показать все услуги"}*/}

                                        {/*</div>*/}


                                    </div>
                                    {/*<hr/>*/}

                                </div>
                            ))
                        }
                    </div>

                    <Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>
                        <div className={'btn-active mt-3 mb-3 '}>
                            Продолжить
                        </div>
                    </Link>


                    <div className={'h-150'}>

                    </div>
                    <Menu></Menu>
                </div>
            )
        }

    }
}

export default Service;