import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";


export class ProfileCreate extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            date: '',
            email: '',
            btnPhone: false,
            btnCode: false,
            genderM: false,
            genderG: false,

        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeGenderM = this.changeGenderM.bind(this);
        this.changeGenderG = this.changeGenderG.bind(this);

    }

    changeGenderG(event) {
        const target = event.target;
        ;

        if (target.checked == true) {
            this.setState({
                genderM: false,
                genderG: true
            });
        }
        else {
            this.setState({
                genderM: false,
                genderG: false
            });
        }

    }

    changeGenderM(event) {
        const target = event.target;
        ;


        if (target.checked == true) {
            this.setState({
                genderM: true,
                genderG: false
            });
        }
        else {
            this.setState({
                genderM: false,
                genderG: false
            });
        }
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }
    sender() {

        let data = {
            name: this.state.name,
            date: this.state.date,
            email: this.state.email,
            genderM: this.state.genderM,
            genderG: this.state.genderG,
        };
        axios({

            url: GlobalVariable.host() + '/mobile/profile/create',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })

    }
    renderButton() {
        let gender = false;
        if(this.state.genderM || this.state.genderG){
            gender = true;
        }
        if (this.state.name && this.state.date  && this.state.email && gender) {
            return (

                <div onClick={()=>this.sender()} className={'btn-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        } else {
            return (

                <div className={'btn-no-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        }
    }


    componentDidMount() {
        // const data = {
        //     code: '1090',
        //     phone: '79625887109'
        // };
        //
        //
        // const data2 = {};
        //
        // axios({
        //
        //     url: GlobalVariable.host() + '/widget/profile/my-record',
        //     headers: {'Content-Type': 'application/json'},
        //     withCredentials: true,
        //     method: "GET",
        //     data: data2,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             recordsPrev: result.data.prevRecord,
        //             recordsNext: result.data.nextRecord,
        //             isLoad: true,
        //             auth: true,
        //             info: 'Двойной Успех',
        //         })
        //     }).catch(error => {
        //     this.setState({auth: false, isLoad: true})
        //     // window.location.href = '/' + this.props.widgetId + '/profile';
        // })

    }


    render() {


        return (
            <div className={'global profile-change'}>
                <div className={'title'}>
                    Стать клиентом<br/>
                    Чио Чио
                </div>
                <div className={'small-title'}>Укажите ваши данные, чтобы использовать
                    все функции приложения
                </div>
                <div className={'form mt-3 mb-3'}>
                    <div className={'input-block d-flex'}>
                        {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                        {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                        <input value={this.state.name} placeholder={'Введите Ваше имя'} onChange={this.changeName}
                               className={'input '}/>
                    </div>
                </div>
                <div className={'d-flex gender'}>
                    <div><input
                        checked={this.state.genderM}
                                onChange={this.changeGenderM}
                        name="m"
                        type="checkbox"
                        className="form-check-input"
                        value=""/>Вы мужчина
                    </div>
                    <div><input
                        checked={this.state.genderG}
                                onChange={this.changeGenderG}
                        name="g"
                        type="checkbox"
                        className="form-check-input"
                        value=""/>Вы женщина
                    </div>
                </div>
                <div className={'form mt-3 mb-3'}>
                    <div className={'input-block d-flex'}>
                        {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                        {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                        {/*<input type="date" id="start" name="trip-start" value={this.state.date} min="1945-01-01" max="2030-12-31" />*/}
                        <input type="date" value={this.state.date}
                               onChange={this.changeDate}
                               className={'input'}/>
                    </div>
                </div>
                <div className={'form mt-3 mb-3'}>
                    <div className={'input-block d-flex'}>
                        {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                        {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                        <input value={this.state.email} placeholder={'Укажите ваш email'} onChange={this.changeEmail}
                               className={'input'}/>
                    </div>
                </div>
                {this.renderButton()}
                <div className={'h-60'}>

                </div>
                <Menu></Menu>
            </div>
        )


    }
}

export default ProfileCreate;