import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";


export class Home extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            phone: '',
            code: '',
            step: 'phone',
            btnPhone: false,
            btnCode: false,
            params: [],
            timer: 45,


        };
        this.timer = this.timer.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCode = this.changeCode.bind(this);
        this.nextStepCode = this.nextStepCode.bind(this);
        this.auth = this.auth.bind(this);

    }

    changePhone(event) {
        let val = event.target.value;
        val = val.replace(/[^+\d]/g, '');


        let num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(4, 7)} ${val.substring(7, val.length)}`;

        num = num.trim();

        if (num.length <= 14) {
            this.setState({phone: num});
        }

        if (num.length == 14) {
            this.setState({btnPhone: true});
        } else {
            this.setState({btnPhone: false});
        }


    }

    changeCode(event) {
        let code = event.target.value.replace(/[^0-9]/g, "")
        if (code.length < 5) {
            this.setState({code: code});
        }
        if (code.length == 4) {
            this.setState({btnCode: true});
        } else {
            this.setState({btnCode: false});
        }


    }

    nextStepCode() {
        const data = {
            phone: this.state.phone.replace(/[^0-9]/g, "")
        };
        axios({

            url: GlobalVariable.host() + '/open/widget/security/check/phone',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status == 'bad') {
                    this.setState({
                        auth: false,
                        step: 'phone'


                    })
                    // window.location.href = '/' + this.props.widgetId + '/profile/login/code';
                } else {
                    this.setState({step: 'code'});
                    if (this.state.timer > 0) {
                        setInterval(this.timer, 1000);
                    }
                }


            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    auth() {
        const data = {
            code: this.state.code,
            phone: this.state.phone.replace(/[^0-9]/g, "")
        };


        axios({

            url: GlobalVariable.host() + '/open/mobile/security/check/code',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status == 'bad') {
                    this.setState({
                        auth: false,
                        // isLoad: true,

                    })
                } else {
                    if (result.data.change == false) {
                        this.setState({
                            auth: true,


                        })
                        if (this.state.params.url) {
                            window.location.href = this.state.params.url;
                        } else {
                            window.location.href = '/home';
                        }

                    } else {
                        this.setState({
                            auth: true,


                        })
                        window.location.href = '/profile';
                    }


                }
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }


    renderButtonPhone() {
        if (this.state.phone.length == 14) {
            return (

                <div onClick={() => this.nextStepCode()} className={'btn-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        } else {
            return (

                <div className={'btn-no-active mt-3 mb-3'}>
                    Продолжить
                </div>

            )
        }
    }

    renderButtonCode() {
        if (this.state.code.length == 4) {
            return (
                <div onClick={() => this.auth()} className={'btn-active mt-3 mb-3'}>
                    Продолжить
                </div>
            )
        } else {
            return (
                <div className={'btn-no-active mt-3 mb-3'}>
                    Продолжить
                </div>
            )
        }
    }

    timer() {


        if (this.state.timer > 0) {
            this.setState({timer: this.state.timer - 1})
        }


    }

    componentDidMount() {
        let searchParams = window.location.search.substr(1).split('&');
        let queryParams = {};

        for (let param of searchParams) {
            let [key, value] = param.split('=');
            queryParams[key] = decodeURIComponent(value || "");
        }
        this.setState({params: queryParams})


    }

    renderRepeatCode() {
        if (this.state.timer == 0) {
            return (
                <p onClick={() => this.nextStepCode()}> Отправить код еще раз</p>

            )
        } else {
            return (

                <p>Отправить код еще раз можно будет, <br/> через {this.state.timer} секунд</p>

            )
        }
    }

    render() {

        if (this.state.step == 'phone') {
            return (

                <div className={'global login'}>
                    <div style={{padding: '15px'}}>
                        <div className={'d-flex mt-3 mb-3'}>
                            <div>
                                <img src={'/img/logo.png'}/>
                            </div>
                            <div className={'pl-3'}>
                                <div className={'title1'}>Введите номер телефона</div>
                                <div className={'title2'}> Чтобы войти или стать клиентом Чио Чио</div>
                            </div>
                        </div>
                        <div className={'form mt-3 mb-3'}>
                            <div className={'input d-flex'}>
                                <div className={'flag'}></div>
                                {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                <input onChange={this.changePhone} style={{paddingLeft: '73px'}}
                                       value={this.state.phone}
                                       placeholder={'7 (xxx) xxx-xx-xx'}
                                       className={'input-phone '}/>
                            </div>
                        </div>
                        <div className={'checkbox mt-3 mb-3'}>
                            <p>Нажимая «Продолжить» вы принимаете Пользовательское соглашение и Политику
                                конфиденциальности</p>
                        </div>
                        {this.renderButtonPhone()}
                    </div>
                    <Menu></Menu>
                </div>
            )
        } else {
            return (
                <div className={'global login'}>
                    <div style={{padding: '15px'}}>
                        <div className={'d-flex mt-3 mb-3'}>
                            <div>
                                <img src={'/img/logo.png'}/>
                            </div>
                            <div className={'pl-3'}>
                                <div className={'title1'}>Введите код</div>
                                <div className={'title2'}> Чтобы войти или стать клиентом Чио Чио</div>
                            </div>
                        </div>
                        <div className={'info mt-3 mb-3'}>
                            <p>Код был отправлен по номеру +{this.state.phone}</p>
                        </div>
                        <div className={'form mt-3 mb-3'}>
                            <div className={'input d-flex'}>
                                {/*<div className={'img'}><img src="/img/phone-false.png"/></div>*/}
                                {/*<div style={{position:"absolute",  paddingLeft:'50px'}} className={''}>+7</div>*/}
                                <input value={this.state.code} placeholder={'x x x x'} onChange={this.changeCode}
                                       className={'input-code '}/>
                            </div>
                        </div>
                        <div className={'info mt-3 mb-3'}>
                            <p>Нужно ответить на входящий звонок, робот продиктует код подтверждения</p>
                        </div>
                        {this.renderButtonCode()}
                        <div className={'info mt-3 mb-3'} style={{color: '#9E9E9E'}}>
                            {this.renderRepeatCode()}
                        </div>
                    </div>
                    <Menu></Menu>
                </div>
            )
        }


    }
}

export default Home;